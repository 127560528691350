exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-application-submitted-js": () => import("./../../../src/pages/application-submitted.js" /* webpackChunkName: "component---src-pages-application-submitted-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contact-us-submitted-js": () => import("./../../../src/pages/contact-us-submitted.js" /* webpackChunkName: "component---src-pages-contact-us-submitted-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individuelle-softwareentwicklung-js": () => import("./../../../src/pages/individuelle-softwareentwicklung.js" /* webpackChunkName: "component---src-pages-individuelle-softwareentwicklung-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-job-apply-js": () => import("./../../../src/pages/job-apply.js" /* webpackChunkName: "component---src-pages-job-apply-js" */),
  "component---src-pages-our-approach-js": () => import("./../../../src/pages/our-approach.js" /* webpackChunkName: "component---src-pages-our-approach-js" */),
  "component---src-pages-our-company-js": () => import("./../../../src/pages/our-company.js" /* webpackChunkName: "component---src-pages-our-company-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-our-work-business-network-platform-development-for-copybook-js": () => import("./../../../src/pages/our-work/business-network-platform-development-for-copybook.js" /* webpackChunkName: "component---src-pages-our-work-business-network-platform-development-for-copybook-js" */),
  "component---src-pages-our-work-dedicated-development-team-for-management-events-js": () => import("./../../../src/pages/our-work/dedicated-development-team-for-management-events.js" /* webpackChunkName: "component---src-pages-our-work-dedicated-development-team-for-management-events-js" */),
  "component---src-pages-our-work-iot-software-development-for-a-leading-electrical-tech-company-js": () => import("./../../../src/pages/our-work/iot-software-development-for-a-leading-electrical-tech-company.js" /* webpackChunkName: "component---src-pages-our-work-iot-software-development-for-a-leading-electrical-tech-company-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-our-work-product-development-for-eduspire-solutions-js": () => import("./../../../src/pages/our-work/product-development-for-eduspire-solutions.js" /* webpackChunkName: "component---src-pages-our-work-product-development-for-eduspire-solutions-js" */),
  "component---src-pages-our-work-tactics-board-app-development-for-e-2-c-js": () => import("./../../../src/pages/our-work/tactics-board-app-development-for-e2c.js" /* webpackChunkName: "component---src-pages-our-work-tactics-board-app-development-for-e-2-c-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-innovation-advisory-js": () => import("./../../../src/pages/services/innovation-advisory.js" /* webpackChunkName: "component---src-pages-services-innovation-advisory-js" */),
  "component---src-pages-services-team-augmentation-js": () => import("./../../../src/pages/services/team-augmentation.js" /* webpackChunkName: "component---src-pages-services-team-augmentation-js" */),
  "component---src-pages-technologies-js": () => import("./../../../src/pages/technologies.js" /* webpackChunkName: "component---src-pages-technologies-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-preview-js": () => import("./../../../src/templates/blog-post-preview.js" /* webpackChunkName: "component---src-templates-blog-post-preview-js" */),
  "component---src-templates-career-preview-js": () => import("./../../../src/templates/career-preview.js" /* webpackChunkName: "component---src-templates-career-preview-js" */),
  "component---src-templates-industry-preview-js": () => import("./../../../src/templates/industry-preview.js" /* webpackChunkName: "component---src-templates-industry-preview-js" */),
  "component---src-templates-service-preview-js": () => import("./../../../src/templates/service-preview.js" /* webpackChunkName: "component---src-templates-service-preview-js" */),
  "component---src-templates-sub-service-preview-js": () => import("./../../../src/templates/sub-service-preview.js" /* webpackChunkName: "component---src-templates-sub-service-preview-js" */),
  "component---src-templates-technology-preview-js": () => import("./../../../src/templates/technology-preview.js" /* webpackChunkName: "component---src-templates-technology-preview-js" */)
}

